// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pa66Vx9TbzpwicX3aP8O{margin:100px auto;width:50px;height:40px;text-align:center;font-size:10px}.Pa66Vx9TbzpwicX3aP8O>div{background-color:#333;height:100%;width:6px;margin-left:3px;display:inline-block;-webkit-animation:G3exJXlEmv7b2fB_rM2m 1.2s infinite ease-in-out;animation:G3exJXlEmv7b2fB_rM2m 1.2s infinite ease-in-out}.Pa66Vx9TbzpwicX3aP8O .g45s9Zm4P1yisHMgXqW2{-webkit-animation-delay:-1.1s;animation-delay:-1.1s}.Pa66Vx9TbzpwicX3aP8O .WAYsy0C4UYdBSrYxip4T{-webkit-animation-delay:-1s;animation-delay:-1s}.Pa66Vx9TbzpwicX3aP8O .ARpjMSitpIUTslZUedT_{-webkit-animation-delay:-0.9s;animation-delay:-0.9s}.Pa66Vx9TbzpwicX3aP8O .g7GZSuYY0W7ZYvTi4XZZ{-webkit-animation-delay:-0.8s;animation-delay:-0.8s}@-webkit-keyframes G3exJXlEmv7b2fB_rM2m{0%,40%,100%{-webkit-transform:scaleY(0.4)}20%{-webkit-transform:scaleY(1)}}@keyframes G3exJXlEmv7b2fB_rM2m{0%,40%,100%{transform:scaleY(0.4);-webkit-transform:scaleY(0.4)}20%{transform:scaleY(1);-webkit-transform:scaleY(1)}}
`, "",{"version":3,"sources":["webpack://./../@depmap/common-components/src/styles/Spinner.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,cAAe,CAChB,0BAGC,qBAAsB,CACtB,WAAY,CACZ,SAAU,CACV,eAAgB,CAChB,oBAAqB,CAErB,gEAA4D,CAC5D,wDAAoD,CACrD,4CAGC,6BAA8B,CAC9B,qBAAsB,CACvB,4CAGC,2BAA4B,CAC5B,mBAAoB,CACrB,4CAGC,6BAA8B,CAC9B,qBAAsB,CACvB,4CAGC,6BAA8B,CAC9B,qBAAsB,CACvB,wCAGC,YAGE,6BAA8B,CAEhC,IACE,2BAA4B,CAAA,CAIhC,gCACE,YAGE,qBAAsB,CACtB,6BAA8B,CAEhC,IACE,mBAAoB,CACpB,2BAA4B,CAAA","sourcesContent":[".spinner {\n  margin: 100px auto;\n  width: 50px;\n  height: 40px;\n  text-align: center;\n  font-size: 10px;\n}\n\n.spinner > div {\n  background-color: #333;\n  height: 100%;\n  width: 6px;\n  margin-left: 3px;\n  display: inline-block;\n\n  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;\n  animation: sk-stretchdelay 1.2s infinite ease-in-out;\n}\n\n.spinner .rect2 {\n  -webkit-animation-delay: -1.1s;\n  animation-delay: -1.1s;\n}\n\n.spinner .rect3 {\n  -webkit-animation-delay: -1s;\n  animation-delay: -1s;\n}\n\n.spinner .rect4 {\n  -webkit-animation-delay: -0.9s;\n  animation-delay: -0.9s;\n}\n\n.spinner .rect5 {\n  -webkit-animation-delay: -0.8s;\n  animation-delay: -0.8s;\n}\n\n@-webkit-keyframes sk-stretchdelay {\n  0%,\n  40%,\n  100% {\n    -webkit-transform: scaleY(0.4);\n  }\n  20% {\n    -webkit-transform: scaleY(1);\n  }\n}\n\n@keyframes sk-stretchdelay {\n  0%,\n  40%,\n  100% {\n    transform: scaleY(0.4);\n    -webkit-transform: scaleY(0.4);\n  }\n  20% {\n    transform: scaleY(1);\n    -webkit-transform: scaleY(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `Pa66Vx9TbzpwicX3aP8O`,
	"sk-stretchdelay": `G3exJXlEmv7b2fB_rM2m`,
	"rect2": `g45s9Zm4P1yisHMgXqW2`,
	"rect3": `WAYsy0C4UYdBSrYxip4T`,
	"rect4": `ARpjMSitpIUTslZUedT_`,
	"rect5": `g7GZSuYY0W7ZYvTi4XZZ`
};
export default ___CSS_LOADER_EXPORT___;
