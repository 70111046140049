// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pju4DHSaDie8XMFtWZ6a{border:5px solid #f3f3f3;border-radius:50%;width:25px;height:25px;margin-right:5px}.p4a7S9Fzx7RPEOJ4XWWG{margin-right:5px}.PUBSGHBg0N4dPqQWj9Vv{border:5px solid green}.BNVv0us0eCEM9CcP99vN{border-top:5px solid crimson}.fcy1Ljrf6macwThRCOJL{border-top:5px solid #3498db;-webkit-animation:PbS4slQll2vULeZBB0gw 2s linear infinite;animation:PbS4slQll2vULeZBB0gw 2s linear infinite}.kbkYGpnZzdX1YyL2Xv1I{border:5px solid lightGray}.r9Kphom5yQDszOo54PQk:after{overflow:hidden;display:inline-block;vertical-align:bottom;-webkit-animation:WQhha_M2qcTZvA13Iqn1 steps(4, end) 900ms infinite;animation:WQhha_M2qcTZvA13Iqn1 steps(4, end) 900ms infinite;content:"\\2026";width:0px}@keyframes WQhha_M2qcTZvA13Iqn1{to{width:20px}}@-webkit-keyframes WQhha_M2qcTZvA13Iqn1{to{width:20px}}
`, "",{"version":3,"sources":["webpack://./../@depmap/common-components/src/styles/ProgressTracker.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAyB,CACzB,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,gBAAiB,CAClB,sBAGC,gBAAiB,CAClB,sBAGC,sBAAuB,CACxB,sBAGC,4BAA6B,CAC9B,sBAGC,4BAA6B,CAC7B,yDAA0C,CAC1C,iDAAkC,CACnC,sBAGC,0BAA2B,CAC5B,4BAGC,eAAgB,CAChB,oBAAqB,CACrB,qBAAsB,CACtB,mEAAwD,CACxD,2DAAgD,CAChD,eAAgB,CAChB,SAAU,CACX,gCAGC,GACE,UAAW,CAAA,CAIf,wCACE,GACE,UAAW,CAAA","sourcesContent":[".loadingAnimationCommon {\n  border: 5px solid #f3f3f3;\n  border-radius: 50%;\n  width: 25px;\n  height: 25px;\n  margin-right: 5px;\n}\n\n.percentComplete {\n  margin-right: 5px;\n}\n\n.success {\n  border: 5px solid green;\n}\n\n.failure {\n  border-top: 5px solid crimson;\n}\n\n.running {\n  border-top: 5px solid #3498db;\n  -webkit-animation: spin 2s linear infinite;\n  animation: spin 2s linear infinite;\n}\n\n.pending {\n  border: 5px solid lightGray;\n}\n\n.loadingEllipsis:after {\n  overflow: hidden;\n  display: inline-block;\n  vertical-align: bottom;\n  -webkit-animation: ellipsis steps(4, end) 900ms infinite;\n  animation: ellipsis steps(4, end) 900ms infinite;\n  content: \"\\2026\"; /* ascii code for the ellipsis character */\n  width: 0px;\n}\n\n@keyframes ellipsis {\n  to {\n    width: 20px;\n  }\n}\n\n@-webkit-keyframes ellipsis {\n  to {\n    width: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingAnimationCommon": `pju4DHSaDie8XMFtWZ6a`,
	"percentComplete": `p4a7S9Fzx7RPEOJ4XWWG`,
	"success": `PUBSGHBg0N4dPqQWj9Vv`,
	"failure": `BNVv0us0eCEM9CcP99vN`,
	"running": `fcy1Ljrf6macwThRCOJL`,
	"spin": `PbS4slQll2vULeZBB0gw`,
	"pending": `kbkYGpnZzdX1YyL2Xv1I`,
	"loadingEllipsis": `r9Kphom5yQDszOo54PQk`,
	"ellipsis": `WQhha_M2qcTZvA13Iqn1`
};
export default ___CSS_LOADER_EXPORT___;
