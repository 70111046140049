// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-dataset-modal summary{cursor:pointer}.upload-dataset-modal #upload-example-table{border-collapse:collapse;border:1px solid black;color:#333}.upload-dataset-modal #upload-example-table th,.upload-dataset-modal #upload-example-table td{border:1px solid black;padding:4px}.upload-dataset-modal .small-space-after-inline{margin-inline-end:4px}.upload-dataset-modal .alert-message-container{display:flex;justify-content:space-between}.upload-dataset-modal .alert-message-container .alert-text-with-spinner-container{display:flex;align-items:center}.upload-dataset-modal .alert-message-container .alert-message-button{color:inherit}.upload-dataset-modal .upload-button-container{display:flex;justify-content:flex-end}.upload-dataset-modal .warning-message{color:"#a94442"}
`, "",{"version":3,"sources":["webpack://./../@depmap/user-upload/src/styles/UserUploadModal.scss"],"names":[],"mappings":"AAEA,8BAGM,cAAe,CAHrB,4CAOM,wBAAyB,CACzB,sBAAuB,CACvB,UAAW,CATjB,8FAaQ,sBAAuB,CACvB,WAAY,CAdpB,gDAmBM,qBAAsB,CAnB5B,+CAuBM,YAAa,CACb,6BAA8B,CAxBpC,kFA2BQ,YAAa,CACb,kBAAmB,CA5B3B,qEAgCQ,aAAc,CAhCtB,+CAqCM,YAAa,CACb,wBAAyB,CAtC/B,uCA0CM,eAAgB","sourcesContent":["// TODO: Get rid of :global and convert the corresponding component to CSS\n// Module format.\n:global {\n  .upload-dataset-modal {\n    summary {\n      cursor: pointer;\n    }\n\n    #upload-example-table {\n      border-collapse: collapse;\n      border: 1px solid black;\n      color: #333;\n\n      th,\n      td {\n        border: 1px solid black;\n        padding: 4px;\n      }\n    }\n\n    .small-space-after-inline {\n      margin-inline-end: 4px;\n    }\n\n    .alert-message-container {\n      display: flex;\n      justify-content: space-between;\n\n      .alert-text-with-spinner-container {\n        display: flex;\n        align-items: center;\n      }\n\n      .alert-message-button {\n        color: inherit;\n      }\n    }\n\n    .upload-button-container {\n      display: flex;\n      justify-content: flex-end;\n    }\n\n    .warning-message {\n      color: \"#a94442\";\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
