// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-associations{background:#ddd;border-top-left-radius:5px;border-top-right-radius:5px}div.result-container .tab-content{border-left:1px solid #ddd;border-right:1px solid #ddd;border-bottom:1px solid #ddd;padding:10px}
`, "",{"version":3,"sources":["webpack://./../@depmap/compute/src/styles/CustomAnalysisResult.scss"],"names":[],"mappings":"AAEA,oBAEI,eAAgB,CAChB,0BAA2B,CAC3B,2BAA4B,CAJhC,kCAQI,0BAA2B,CAC3B,2BAA4B,CAC5B,4BAA6B,CAC7B,YAAa","sourcesContent":["// TODO: Get rid of :global and convert the corresponding component to CSS\n// Module format.\n:global {\n  .close-associations {\n    background: #ddd;\n    border-top-left-radius: 5px;\n    border-top-right-radius: 5px;\n  }\n\n  div.result-container .tab-content {\n    border-left: 1px solid #ddd;\n    border-right: 1px solid #ddd;\n    border-bottom: 1px solid #ddd;\n    padding: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
