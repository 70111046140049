// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.depmap-accordion .accordion-header{cursor:pointer;background-color:#ddd;border:none;padding:8px;overflow:hidden;text-overflow:ellipsis}.depmap-accordion details summary{list-style-type:none}.depmap-accordion details summary::-webkit-details-marker{display:none}.depmap-accordion details summary::before{content:"+";float:right;padding-right:16}.depmap-accordion details[open] summary{margin-bottom:0.5rem}.depmap-accordion details[open] summary::before{content:"-";float:right;padding-right:8}
`, "",{"version":3,"sources":["webpack://./../@depmap/interactive/src/styles/accordion.scss"],"names":[],"mappings":"AAEA,oCAGM,cAAe,CACf,qBAAsB,CACtB,WAAY,CACZ,WAAY,CACZ,eAAgB,CAChB,sBAAuB,CAR7B,kCAaQ,oBAAqB,CAb7B,0DAgBU,YAAa,CAhBvB,0CAoBU,WAAY,CACZ,WAAY,CACZ,gBAAiB,CAtB3B,wCA4BU,oBAAqB,CA5B/B,gDA+BY,WAAY,CACZ,WAAY,CACZ,eAAgB","sourcesContent":["// TODO: Get rid of :global and convert the corresponding component to CSS\n// Module format.\n:global {\n  .depmap-accordion {\n    .accordion-header {\n      cursor: pointer;\n      background-color: #ddd;\n      border: none;\n      padding: 8px;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n\n    details {\n      summary {\n        list-style-type: none;\n\n        &::-webkit-details-marker {\n          display: none;\n        }\n\n        &::before {\n          content: \"+\";\n          float: right;\n          padding-right: 16;\n        }\n      }\n\n      &[open] {\n        summary {\n          margin-bottom: 0.5rem;\n\n          &::before {\n            content: \"-\";\n            float: right;\n            padding-right: 8;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
