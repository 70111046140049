export interface Dataset {
  [key: string]: any;
  allowed_values: string[] | null;
  feature_type: string | null;
  data_type: string | null;
  priority: number | null;
  taiga_id: string | null;
  group_id: any;
  id: string;
  is_transient: boolean;
  name: string;
  sample_type: string | null;
  units: string;
  value_type: string | null;
  dataset_metadata?: any;
}

export interface DatasetUpdateArgs {
  [key: string]: any;
  id: string;
  group_id: any;
  name?: string;
  data_type?: string | null;
  priority?: number | null;
  units?: string;
  dataset_metadata?: any;
}

export interface AddCustDatasetArgs {
  name: string;
  units: string;
  feature_type: string;
  sample_type: string;
  value_type: DatasetValueType;
  data_file: any;
  is_transient: boolean;
}

export enum DatasetValueType {
  continuous = "continuous",
  categorical = "categorical",
}

export interface DatasetTableData {
  id: string;
  name: string;
  groupName: string;
  featureType: string | null;
  sampleType: string | null;
  dataType: string | null;
}
