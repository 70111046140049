// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lsbw7ldbmmTRwzkbdyWk{display:flex;flex-direction:column}.uUnv8cBRUoJfTUC87XPT{max-width:160px;margin:10px 33px 0 0}.NHp8oijw14Zn0nWK2BDq{background-color:transparent}.NHp8oijw14Zn0nWK2BDq:hover{background-color:#deebff}.HYubAfriqQH_WOpkdhbB{color:#fff;background-color:#2d8de1;border:1px solid #1c76c4;padding:0 4px;border-radius:3px;margin-right:3px}
`, "",{"version":3,"sources":["webpack://./../@depmap/data-explorer-2/src/styles/ContextSelector.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,qBAAsB,CACvB,sBAGC,eAAgB,CAChB,oBAAqB,CACtB,sBAGC,4BAA6B,CAD/B,4BAII,wBAAoC,CACrC,sBAID,UAAW,CACX,wBAAyB,CACzB,wBAAyB,CACzB,aAAc,CACd,iBAAkB,CAClB,gBAAiB","sourcesContent":[".contextSelector {\n  display: flex;\n  flex-direction: column;\n}\n\n.saveAsContextButton {\n  max-width: 160px;\n  margin: 10px 33px 0 0;\n}\n\n.optimizedSelectOptionHover {\n  background-color: transparent;\n\n  &:hover {\n    background-color: rgb(222, 235, 255);\n  }\n}\n\n.negatedContextBadge {\n  color: #fff;\n  background-color: #2d8de1;\n  border: 1px solid #1c76c4;\n  padding: 0 4px;\n  border-radius: 3px;\n  margin-right: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextSelector": `Lsbw7ldbmmTRwzkbdyWk`,
	"saveAsContextButton": `uUnv8cBRUoJfTUC87XPT`,
	"optimizedSelectOptionHover": `NHp8oijw14Zn0nWK2BDq`,
	"negatedContextBadge": `HYubAfriqQH_WOpkdhbB`
};
export default ___CSS_LOADER_EXPORT___;
