// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.association-table-container .ReactTable .rt-th,.association-table-container .ReactTable .rt-td{padding:0;text-align:center;white-space:normal}.association-table-container .ReactTable .rt-thead.-filters input,.association-table-container .ReactTable .rt-thead.-filters select{padding:0}.association-table-container .ReactTable .rt-th,.association-table-container .ReactTable .rt-td{cursor:pointer}.striped-row:nth-child(odd){background-color:#f2f2f2}
`, "",{"version":3,"sources":["webpack://./../@depmap/interactive/src/styles/AssociationTable.scss"],"names":[],"mappings":"AAEE,gGAEE,SAAU,CACV,iBAAkB,CAClB,kBAAmB,CACpB,qIAIC,SAAU,CACX,gGAYG,cAAe,CAPrB,4BAaM,wBAAyB","sourcesContent":["// based on depmap/static/css/shared/narrow_react_table.scss\n@mixin narrow-react-table {\n  .ReactTable .rt-th,\n  .ReactTable .rt-td {\n    padding: 0;\n    text-align: center;\n    white-space: normal;\n  }\n\n  .ReactTable .rt-thead.-filters input,\n  .ReactTable .rt-thead.-filters select {\n    padding: 0;\n  }\n}\n\n// TODO: Get rid of :global and convert the corresponding component to CSS\n// Module format.\n:global {\n  /* React table, cursor pointer for entire row */\n  .association-table-container {\n    @include narrow-react-table;\n\n    .ReactTable .rt-th,\n    .ReactTable .rt-td {\n      cursor: pointer;\n    }\n  }\n\n  .striped-row {\n    &:nth-child(odd) {\n      background-color: #f2f2f2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
