// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FoFCZzxkFCs1F8UJDU_K{display:flex}.JQDhaZP3r4AG6uCdGlQt{display:inline-block}.JQDhaZP3r4AG6uCdGlQt:not(:first-child) label{margin-top:5px}.C9YzgsTAIKK7anMhppDJ{display:inline-block;position:relative;top:0;left:-10px;margin-right:0;min-width:10px;min-height:38px;border:1px solid #ccc;border-right:none;border-radius:4px 0 0 4px}.IUoOQPPI84kndEJfyYHD{text-align:start;font-size:12px}.IUoOQPPI84kndEJfyYHD label{margin-right:6px}.IUoOQPPI84kndEJfyYHD._VNqtcaqYIaTlyLHT_nt{font-size:13px;display:inline-block}.Fh_X_nkmhlYx6qhppDTN{display:inline-block;width:162px;font-size:12px}.Fh_X_nkmhlYx6qhppDTN.yW8XkW6v9EtUPd0MajMj{margin-left:-10px}.Fh_X_nkmhlYx6qhppDTN.yW8XkW6v9EtUPd0MajMj>div{border-radius:0 4px 4px 0}.BVb4Dj2rzxO6ZjU2dlpG>div{border:1px solid #a94442}.BVb4Dj2rzxO6ZjU2dlpG>div:hover{border:1px solid #a94442}#extended-select-tooltip:not(:last-child){display:none}#tooltip-blocker+.tooltip,#tooltip-blocker+.tooltip+.tooltip,#tooltip-blocker+.tooltip+.tooltip+.tooltip{display:none}
`, "",{"version":3,"sources":["webpack://./../@depmap/data-explorer-2/src/styles/ExtendedSelect.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACd,sBAGC,oBAAqB,CADvB,8CAKM,cAAe,CAChB,sBAKH,oBAAqB,CACrB,iBAAkB,CAClB,KAAM,CACN,UAAW,CACX,cAAe,CACf,cAAe,CACf,eAAgB,CAChB,qBAAsB,CACtB,iBAAkB,CAClB,yBAA0B,CAC3B,sBAGC,gBAAiB,CACjB,cAAe,CAFjB,4BAKI,gBAAiB,CALrB,2CASI,cAAe,CACf,oBAAqB,CACtB,sBAID,oBAAqB,CACrB,WAAY,CACZ,cAAe,CAHjB,2CAMI,iBAAkB,CANtB,+CASM,yBAA0B,CAC3B,0BAKH,wBAAyB,CAD3B,gCAII,wBAAyB,CAC1B,0CAMG,YAAa,CAHnB,yGAWM,YAAa","sourcesContent":[".container {\n  display: flex;\n}\n\n.ExtendedSelect {\n  display: inline-block;\n\n  &:not(:first-child) {\n    label {\n      margin-top: 5px;\n    }\n  }\n}\n\n.swatch {\n  display: inline-block;\n  position: relative;\n  top: 0;\n  left: -10px;\n  margin-right: 0;\n  min-width: 10px;\n  min-height: 38px;\n  border: 1px solid #ccc;\n  border-right: none;\n  border-radius: 4px 0 0 4px;\n}\n\n.selectorLabel {\n  text-align: start;\n  font-size: 12px;\n\n  label {\n    margin-right: 6px;\n  }\n\n  &.inlineLabel {\n    font-size: 13px;\n    display: inline-block;\n  }\n}\n\n.Select {\n  display: inline-block;\n  width: 162px;\n  font-size: 12px;\n\n  &.withSwatch {\n    margin-left: -10px;\n\n    & > div {\n      border-radius: 0 4px 4px 0;\n    }\n  }\n}\n\n.selectError > div {\n  border: 1px solid #a94442;\n\n  &:hover {\n    border: 1px solid #a94442;\n  }\n}\n\n:global {\n  #extended-select-tooltip {\n    &:not(:last-child) {\n      display: none;\n    }\n  }\n\n  #tooltip-blocker {\n    & + .tooltip,\n    & + .tooltip + .tooltip,\n    & + .tooltip + .tooltip + .tooltip {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FoFCZzxkFCs1F8UJDU_K`,
	"ExtendedSelect": `JQDhaZP3r4AG6uCdGlQt`,
	"swatch": `C9YzgsTAIKK7anMhppDJ`,
	"selectorLabel": `IUoOQPPI84kndEJfyYHD`,
	"inlineLabel": `_VNqtcaqYIaTlyLHT_nt`,
	"Select": `Fh_X_nkmhlYx6qhppDTN`,
	"withSwatch": `yW8XkW6v9EtUPd0MajMj`,
	"selectError": `BVb4Dj2rzxO6ZjU2dlpG`
};
export default ___CSS_LOADER_EXPORT___;
