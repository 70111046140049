// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XCtGKDQnkvDdkqS1DYH7{display:flex;gap:5px}.XCtGKDQnkvDdkqS1DYH7 label{all:unset;font-weight:bold;font-size:12px}.XCtGKDQnkvDdkqS1DYH7 button{all:unset;width:50px;height:16px;cursor:pointer;border-radius:6px;border:1px solid #132041}.XCtGKDQnkvDdkqS1DYH7 button:focus-visible{outline:blue auto 1px;outline:-webkit-focus-ring-color auto 1px}.XCtGKDQnkvDdkqS1DYH7 button::after{content:"";display:inline-block;position:relative;top:-0.5px;left:2px;width:14px;height:14px;background-color:#4479b2;border-radius:50%}.XCtGKDQnkvDdkqS1DYH7 button.uALC6c7ybwX3LLR7kOq5::after{left:34px}.XCtGKDQnkvDdkqS1DYH7 button[disabled]{cursor:not-allowed}.XCtGKDQnkvDdkqS1DYH7.y8GejUTv_ReTqkQd0KsJ button::after{transition:left 200ms ease-out}.XCtGKDQnkvDdkqS1DYH7.mBfV_35HvSYo3PliLEFl{cursor:not-allowed}.XCtGKDQnkvDdkqS1DYH7.mBfV_35HvSYo3PliLEFl label{color:#999}.XCtGKDQnkvDdkqS1DYH7.mBfV_35HvSYo3PliLEFl button{border:1px solid #aaa;background-color:#fafafa}.XCtGKDQnkvDdkqS1DYH7.mBfV_35HvSYo3PliLEFl button::after{background-color:#b3c9e2}
`, "",{"version":3,"sources":["webpack://./../@depmap/common-components/src/styles/ToggleSwitch.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,OAAQ,CAFV,4BAKI,SAAU,CACV,gBAAiB,CACjB,cAAe,CAPnB,6BAWI,SAAU,CACV,UAAW,CACX,WAAY,CACZ,cAAe,CACf,iBAAkB,CAClB,wBAAyB,CAhB7B,2CAmBM,qBAAsB,CACtB,yCAA0C,CApBhD,oCAwBM,UAAW,CACX,oBAAqB,CACrB,iBAAkB,CAClB,UAAW,CACX,QAAS,CACT,UAAW,CACX,WAAY,CACZ,wBAAyB,CACzB,iBAAkB,CAhCxB,yDAoCM,SAAU,CApChB,uCAwCM,kBAAmB,CAxCzB,yDA8CM,8BAA+B,CA9CrC,2CAmDI,kBAAmB,CAnDvB,iDAsDM,UAAW,CAtDjB,kDA0DM,qBAAsB,CACtB,wBAAyB,CA3D/B,yDA8DQ,wBAAyB","sourcesContent":[".ToggleSwitch {\n  display: flex;\n  gap: 5px;\n\n  label {\n    all: unset;\n    font-weight: bold;\n    font-size: 12px;\n  }\n\n  button {\n    all: unset;\n    width: 50px;\n    height: 16px;\n    cursor: pointer;\n    border-radius: 6px;\n    border: 1px solid #132041;\n\n    &:focus-visible {\n      outline: blue auto 1px;\n      outline: -webkit-focus-ring-color auto 1px;\n    }\n\n    &::after {\n      content: \"\";\n      display: inline-block;\n      position: relative;\n      top: -0.5px;\n      left: 2px;\n      width: 14px;\n      height: 14px;\n      background-color: #4479b2;\n      border-radius: 50%;\n    }\n\n    &.toggledOn::after {\n      left: 34px;\n    }\n\n    &[disabled] {\n      cursor: not-allowed;\n    }\n  }\n\n  &.animating {\n    button::after {\n      transition: left 200ms ease-out;\n    }\n  }\n\n  &.disabled {\n    cursor: not-allowed;\n\n    label {\n      color: #999;\n    }\n\n    button {\n      border: 1px solid #aaa;\n      background-color: #fafafa;\n\n      &::after {\n        background-color: #b3c9e2;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToggleSwitch": `XCtGKDQnkvDdkqS1DYH7`,
	"toggledOn": `uALC6c7ybwX3LLR7kOq5`,
	"animating": `y8GejUTv_ReTqkQd0KsJ`,
	"disabled": `mBfV_35HvSYo3PliLEFl`
};
export default ___CSS_LOADER_EXPORT___;
