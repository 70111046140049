// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom_csv_example_table{display:inline;font-size:12px}.custom_csv_example_table td{padding:2px;border:1px solid #ddd}.has-error{color:#a94442}
`, "",{"version":3,"sources":["webpack://./../@depmap/compute/src/styles/CustomOrCatalogVectorSelect.scss"],"names":[],"mappings":"AAEA,0BAEI,cAAe,CACf,cAAe,CAHnB,6BAKM,WAAY,CACZ,qBAAsB,CAN5B,WAYI,aAAc","sourcesContent":["// TODO: Get rid of :global and convert the corresponding component to CSS\n// Module format.\n:global {\n  .custom_csv_example_table {\n    display: inline;\n    font-size: 12px;\n    td {\n      padding: 2px;\n      border: 1px solid #ddd;\n    }\n  }\n\n  .has-error {\n    //  mimics the color from react bootstrap forms.less, but allows us to apply it with just the has-error class\n    color: #a94442;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
